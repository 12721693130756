/* .homeHR {
    border: none;
    color: #868686;
    letter-spacing: 1.35em;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 700;
    margin: 50px 0;
}

.homeHR:before {
    content: '•••';
} */

/* .ql-custom-hr {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='gray' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M5 12h14' /%3E%3C/svg%3E%0A") !important;
}

.ql-custom-hr:hover {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='%2306c' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M5 12h14' /%3E%3C/svg%3E%0A") !important;
}

iframe {
    width: 800px !important;
    height: 600px !important;
}

@media (max-width: 768px) {
    iframe {
        width: 100% !important;
        height: 400px !important;
    }
} */


@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
	body {
		margin: 0 !important;
	}
}

.main-container {
	font-family: 'Lato';
	width: 100%;
}

.ck-content {
	font-family: 'Lato';
	line-height: 1.6;
	word-break: break-word;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
    background: transparent;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
    background: transparent;
}

.ck.ck-sticky-panel__content.ck-sticky-panel__content_sticky {
    top: 100px !important;
}

@media (max-width: 800px) {
    .ck.ck-sticky-panel__content.ck-sticky-panel__content_sticky {
        top: 75px !important;
    }
}

@media (min-width: 1200px) {
    .ck.ck-sticky-panel__content.ck-sticky-panel__content_sticky {
        top: 90px !important;
    }
}

/* .editor-container_classic-editor .editor-container__editor {
    min-width: 100%;
} */


/* .editor-container_include-block-toolbar {
} */