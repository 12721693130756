body {
  margin: 0;
  padding: 0;
}

.container {
  max-width: 800px;
  margin: 20px auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.AdminUserList ul {
  list-style: none;
  padding: 0;
}

.AdminUserList li {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.username {
  font-size: 18px;
  font-weight: bold;
  color: #007bff;
}

.adminBadge {
  -webkit-text-fill-color: #0000;
  background: linear-gradient(90deg,#f32170,#ff6b08,#cf23cf);
  -webkit-background-clip: text;
  font-size: 18px;
  font-weight: 700;
}

.tags {    
  text-transform: uppercase;
  user-select: none;
  font-size: 14px;
  color: #888;
}

.AdminUserList select {
  margin-right: 10px;
  padding: 8px;
}

.AdminUserList button {
  padding: 8px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.ourAdmin{
  color: #888;
}

.tagSelect {
  padding: 8px;
  display: flex;
}

.tagn {
  font-size: 10px;
  margin: 0 20px;
  text-transform: lowercase;
}

.PrevievShowButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #6a67ce!important;
  border-bottom: 1px solid #6a67ce!important;
  margin-bottom: 20px;
}

.PrevievShowButton a{
  margin: 15px 0;
  padding: 8px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}

.admin {
  color: red;
}

.moderator {
  color: orange;
}

.editor {
  color: purple;
}

.master-writer {
  color: blue;
}

.writer {
  color: rgb(35, 87, 82);;
}

.user {
  color: grey;
}

.tagSelect select option {
  font-weight: 700;
  text-align: center;
}
  
select option[value="user"] {
  color: grey;
}

select option[value="writer"] {
  color: rgb(35, 87, 82);
}

select option[value="master-writer"] {
  color: blue;
}

select option[value="editor"] {
  color: purple;
}

select option[value="moderator"] {
  color: orange;
}

select option[value="admin"] {
  color: red;
}

.AdminUserTable {
  max-height: 800px;
  overflow-y: auto;
  border-collapse: collapse;
  width: 100%;
}

.AdminUserTable th, .AdminUserTable td {
  border: 1px solid #ddd;
  padding: 15px;
  text-align: left;
}

.searchContainer {
  margin-bottom: 10px;
}

#search {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
}

#search:focus {
  outline: none;
  border-color: #007bff;
}


.warning-edit-area{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.warning-inside {
  margin: 20px 0;
  width: 100%;
  display: grid;
  padding: 10px;
  background-color: red;
}

.warning-inside textarea {
  width: 100%;
  height: 40px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  resize: none;
}

.postCount {
  position: absolute;
  margin-left: 300px;
  background-color: red;
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 10px;
}


.admin-page {
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  background-color: var(--color-white);
}

.admin-header {
  background: #4e73df;
  color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 20px;
}

.btn-primary {
  background: #4e73df;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.badge {
  background: red;
  color: white;
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 10px;
}

.admin-table {
  width: 100%;
  background: #f9f9f9;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.admin-table th, .admin-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.admin-table th {
  background: #4e73df;
  color: white;
}

.admin-table tr:hover {
  background: #f1f1f1;
}
