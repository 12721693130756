.ProfilePageMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1rem;
    margin: 0 auto;
    max-width: 1200px;
}

.ppArea {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ppArea img{
    width: 100%;
    height: 100%;
    max-width: 64px;
    max-height: 64px;
    object-fit: cover;
    border-radius: 50%;
}

.ProfilePageMain h1 {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.ProfileCard {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 15px;
    max-width: 400px;
    background: var(--color-dark);
    border-radius: 25px;
    padding: 10px;
}

.email {
    color: var(--color-white);
}

content:hover {
    filter: brightness(0.2);
}

.ppContent:hover::after {
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' width='32px' stroke='white' class='w-6 h-6'><path stroke-linecap='round' stroke-linejoin='round' d='M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3' /></svg>");
    position: absolute;
    margin-left: 0px;
    margin-top: -68px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: #00000080;
    display: flex;
    align-items: center;
    justify-content: center;
}

.submit-button {
    position: absolute;
}

.submit-button button {
    background: var(--color-danger);
    color: var(--color-white);
    padding: 5px;
    font-weight: bold;
    border: 1px solid var(--color-white);
    text-transform: uppercase;
    max-width: 90px;
    pointer-events: inherit;
}


.ChangePP {
    position: absolute;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
}

.ChangePP::file-selector-button {
    border-radius: 50%;
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.BlogTitle {
    color: var(--color-primary);
    font-weight: bold;
    font-size: 22px;
}

.BlogSummary {
    color: var(--color-dark-light);
    font-size: 14px;
    margin: 2px 0 0 15px;
}

.LastPostImageOverlay img {
    width: 100%;
    height: 100%;
    max-width: 160px;
    max-height: 160px;
    object-fit: cover;
    border-radius: 15px;
    background: linear-gradient(to bottom, rgba(28, 34, 59, 0.52) 50%, rgba(0, 0, 0, 0));
    filter: blur(2px) brightness(0.25) saturate(1.5);
}

.LastPostImage {
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 25px;
}

.LastPostImageOverlay {
    width: 160px;
    height: 160px;
    border: 2px solid var(--color-primary);
    border-radius: 15px;
}

.LastPostTitle {
    position: absolute;
    max-width: 155px;
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: -155px 0 0 10px;
    color: white;
    font-size: 16px;
    filter: drop-shadow(0px 0px 5px black) contrast(1.5) brightness(1.5); 
}

.LastArea {
    display: grid;
    justify-items: center;
}

.LastArea h2 {
    text-align: center;
}

.LastArea h2 span {
    text-transform: capitalize;
}

.LastArea button {
    width: 100px;
    margin-top: 25px;
    color: white;
}

.premium {
    font-weight: 700 !important;
    background: linear-gradient(120deg,#21b1f3,#00510d,#cfb523,#f32170,#21b1f3,#00510d,#cfb523);
    background-size: 300% 300%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradient 3s linear infinite;
    user-select: none;
}

/* PREMIUN PAGE */
.prePage {
    display: grid;
    justify-items: center;
}

.prePage span {
    font-size: 1rem;
    font-weight: 700;
    color: #7c00ad;
}   

.premium-page {
    background: #fff;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    border-radius: 15px;
}

.premium-page a {
    text-decoration: none
}

.premium-page p {
    color: var(--color-black);
}

.premiumHeader2 {
    text-align: center;
    font-weight: 700 !important;
    background: linear-gradient(120deg, #21b1f3, #00510d, #cfb523, #f32170, #21b1f3, #00510d, #cfb523);
    background-size: 300% 300%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradient 3s linear infinite;
  }

.checkbox-container {
    margin-top: 20px;
    color: var(--color-danger);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    user-select: none;
}

.checkbox-container input[type="checkbox"] {
    width: 16px;
    height: 16px;
}

.continue-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
}

.continue-button2 {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
}

.continue-button:hover {
    background-color: #0056b3;
}

.continue-button2:hover {
    background-color: #00510d;
}

.premiumAvantage {
    font-weight: 700 !important;
    background: linear-gradient(120deg, #FF3CAC, #784BA0, #2B86C5, #FF3CAC, #784BA0, #2B86C5);
    background-size: 300% 300%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradient 9s linear infinite;
}

@keyframes gradient {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 100% 100%;
    }
}