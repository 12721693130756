.nfpbg {
  animation:slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #2b5b92 50%, #7c5eff 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
  
}

.bg2 {
  animation-direction:alternate-reverse;
  animation-duration:4s;
  
}

.bg3 {
  animation-duration:5s;
}

.contentNotFound {
  background-color:rgba(255,255,255,.8);
  border-radius:.25em;
  box-shadow:0 0 .25em rgba(0,0,0,.25);
  box-sizing:border-box;
  left:50%;
  padding:10vmin;
  position:absolute;
  text-align:center;
  top:50%;
  transform:translate(-50%, -50%);
  color: var(--color-black);
}

.NotFoundPage h1 {
  font-family:monospace;
  color: var(--color-black);
}

@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}