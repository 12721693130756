@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
	body {
		margin: 0 !important;
	}
}

.ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
    border: none !important;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    border: none !important;
}

.main-container {
	font-family: 'Lato';
	width: 100%;
}

.ck-content {
	font-family: 'Lato';
	line-height: 1.6;
	word-break: break-word;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
    background: transparent;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
    background: transparent;
}

.ck.ck-sticky-panel__content.ck-sticky-panel__content_sticky {
    top: 85px !important;
}

/* .editor-container_classic-editor .editor-container__editor {
    min-width: 100%;
} */


/* .editor-container_include-block-toolbar {
} */