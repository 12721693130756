/* Main Css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0');

:root {
  --color-primary: #b37024;
  --color-danger: #F44336;
  --color-success: #4CAF50;
  --color-warning: #FFC107;
  --color-info: #2196F3;
  --color-info-dark: #7d8da1;
  --color-white: #FFFFFF;
  --color-content: #fff0f0;
  --color-white2: #ddd;
  --color-black: #000000;
  --color-nav: rgba(150, 150, 150, 0.9);
  --color-dark: #212121;
  --color-dark-varient: #b37024;
  --color-light: rgb(100, 100, 100);
  --color-light-varient: rgba(125, 141, 161, 0.5);
  --color-dark-light: rgba(0, 0, 0, 0.685);
  --color-background: #F6F6F9;
  --color-select-bg: #a1a1a1;

  --color-text-primary: #222;
  --color-text-secondary: #696969;
  --color-text-author: #191970;


  --border-radius: 0.5rem;
  --border-radius-sm: 0.25rem;
  --border-radius-1: 0.4rem;
  --border-radius-2: 1.2rem;

  --padding: 1.2rem;
  --padding-sm: 0.8rem;
  --padding-1: 1.2rem;

  --box-shadow: 0 2rem 3rem var(--color-light);
  --box-shadow1: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

}

.dark-mode-variables {
  --color-background: #181a1e;
  --color-dark: #edeffd;
  --color-nav: rgba(0, 0, 0, 0.9);
  --color-white: #202528;
  --color-text-author: #2f2fc4;
  --color-dark-varient: #b37024;
  --color-light: rgba(190, 190, 190, 0.5);
  --color-light-varient: rgb(95, 111, 131);
  --color-dark-light: rgba(255, 255, 255, 0.25);
  --box-shadow: 0 2rem 3rem var(--color-light);
}


.dark-mode {
  background-color: var(--color-light);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.6rem;
  width: 4.2rem;
  cursor: pointer;
  border-radius: var(--border-radius-1);
  user-select: none;
}

.dark-mode span {
  font-size: 1.2rem;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark-mode span.active {
  background-color: var(--color-primary);
  color: var(--color-white);
  border-radius: var(--border-radius-1);
}

h1,h2,h3,h4,h5,h6{
  color: var(--color-dark);
}

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  background-color: var(--color-background);
  color: var(--color-dark);
}

a {
  cursor: pointer;
}

img {
  max-width: 100%;
}

main {
  display: grid;
  grid-template-areas:
      'warning'
      'header'
      'main'
      'footer';
  grid-template-columns: auto;
  grid-template-rows: auto auto 1fr auto;
  height: 100vh;
}

.content {
  padding: 0;
  margin: 0 auto;
  max-width: 960px;
  width: 100%;
  display: grid;
  grid-template-areas:
      'warning'
      'header'
      'main'
      'footer';
  grid-template-columns: auto;
  grid-template-rows: auto auto 1fr auto;
}

.content h3 {
  border-radius: 15px;
}

.dj-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dj-center span {
  color: red;
  background: var(--color-dark);
  padding: 6px;
  margin: 5px;
  border-radius: 20px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
}


/* HomePage Css */
/* Kesfet */
.areas {
  display: flex;
  justify-content: space-around;
  gap: 22px;
  margin-bottom: 22px;
}

.buttonArea {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}


/* Slider */
.slider-container {
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  overflow: hidden;
}

.slider-item {
  position: relative;
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 1.9), rgba(0, 0, 0, 0.6));
  box-shadow: inset 0px -20px 10px 10px #000000ab;
  color: white;
  padding: 20px;
  box-sizing: border-box;
}

.title-overlay h3 {
  font-size: 1.5rem;
  margin: 0;
  font-weight: bold;
  color: var(--color-white2);
}

.slick-prev,
.slick-next {
  z-index: 10 !important;
  width: 40px !important;
  height: 40px !important;
  /* background: rgba(0, 0, 0, 0.5) !important; */
  background: radial-gradient( circle, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100% ) !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: white !important;
}

.slick-prev:hover,
.slick-next:hover {
  background: rgba(0, 0, 0, 0.8) !important;
}

.slick-prev {
  left: 15px !important;
}

.slick-next {
  right: 15px !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 20px !important;
  color: white !important;
  opacity: 1 !important;
}

.slick-dots {
  background-color: var(--color-dark);
  position: relative !important;
  bottom: 10px !important;
}

.slick-dots li button:before {
  font-size: 10px !important;
  color: var(--color-white) !important;
}

.slick-dots li.slick-active button:before {
  color: var(--color-white) !important;
}

.lastPostImageOverlay {
  position: relative;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 1px solid var(--color-warning);
}

.lastPostImage {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.lastPostImageGame {
  max-height: 100%;
  max-width: max-content;
  object-fit: contain;
  filter: blur(1px);
}

.lastPostTitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.2rem;
  text-transform: capitalize;
  font-weight: bold;
  text-align: center;
  font-weight: bold;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.85);
  /* border-radius: 5px; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lastPostTitle span {
  filter: drop-shadow(0px 0px 8px #ffee0050);
}

.homeHR {
  border: none;
  color: #868686;
  letter-spacing: 1.35em;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 700;
  margin: 50px 0;
}

.homeHR:before {
  content: '•••';
}

div.HeadImage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  overflow: hidden;
  margin-bottom: 40px;
}

div.HeadImage img {
  object-fit: cover;
  object-position: center;
  width: 100%;
}

div.vievButtonArea {
  display: flex;
  justify-content: center;
  align-items: center;
}

button.ViewButton {
  margin: 10px 0;
  background: var(--color-info);
  color: var(--color-dark);
  font-weight: 900;
  text-align: center;
  max-width: 300px;
}

div.post {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #6a67ce!important;
}

div.post div.text h2 {
  margin: 0;
  font-size: 1.8rem;
}

div.post div.text a{
  text-decoration: none;
  color: inherit;
}

div.text span {
  /* position: absolute; */
  /* margin-left: -190px; */
  background-color: var(--color-warning);
  color: var(--color-black);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  /* margin-top: 5px; */
}

div.text h1 {
  margin: 0;
}

span.PostTagsAreaAll {
  display: flex;
  justify-content: center;
  align-self: center;
}

div.post p.info {
  margin: 6px 0;
  color: var(--color-text-secondary);
  font-size: .7rem;
  font-weight: bold;
  display: flex;
  gap: 10px;
}

div.PostTags {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-bottom: 10px;
}

div.PostTags span {
  background-color: var(--color-warning);
  color: var(--color-black);
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 5px;
  font-size: 12px;
  font-weight: bold;
}

.PostTagArea {
  display: flex;
  align-items: stretch;
}

.PostTagArea select {
  height: 100%;
  border-radius: 10px 0 0 10px;
}

.PostTagArea button {
  height: 100%;
  border-radius: 0 10px 10px 0;
  color: var(--color-white2);
  font-weight: 700;
  font-size: large;
  text-align: center;
}

pre.ql-syntax {
  background-color: #00071c !important;
}

/* ALL POSTS */
div.allPosts {
  width: 300px;
  height: initial;
  max-height: 410px;
  display: grid;
  background: var(--color-content);
  border-radius: 15px;
  color: var(--color-dark);
  margin-bottom: 25px;
}

div.allPosts div.text {
  padding: 10px;
  border: 1px solid rgba(155,155,155,.2);
  border-radius: 0 0 15px 15px;
  height: 170px;
}

div.allPosts div.text a {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
}

div.allPosts p.info {
  margin: 6px 0;
  color: var(--color-text-secondary);
  font-size: .7rem;
  font-weight: bold;
  display: grid;
}

div.allPosts p.summary {
  float: left;
  color: #4e4e4e;
  font-size: 13px;
  /* display: -webkit-box; */
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-block-start: 0;
}

div.allPosts h1 {
  margin: 0;
  font-size: 1.4rem;
  color: var(--color-dark);
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #0f0f0f;
  line-height: 20px;
  font-size: 16px;
}

div.allPosts div.image img{
  object-fit: cover;
  object-position: center;
  width: 100%;
  border-radius: 15px 15px 0 0;
  max-height: 200px;
  height: 200px;
}

div.allPosts div.image {
  height: 200px;
}

div.AllPostsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin: 20px auto;
  gap: 20px;
  height: min-content;
}

/* Begeniler */
div.LikesArea::before,
.related-posts::before {
  background-color: var(--color-dark);
  border: none;
  border-radius: 25px;
  clear: both;
  content: "";
  display: block;
  height: 3px;
  width: 60%;
  place-self: center;
}

.related-posts h1 {
  text-align: start !important;
}

.related-posts .image a {
  width: 100%;
}

.related-posts::before {
  margin-top: 60px;
}

div.LikesArea {
  margin: 0 0 10px 0;
  padding: 10px;
}

.LikeButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.LikeButtons button {
  animation-name: SizeUp;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

div.LikesArea button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 20px;
  background: var(--color-info);
}

div.LikesArea button.liked {
  background-color: var(--color-primary);
}
div.LikesArea button.superliked {
  background-color: var(--color-primary);
}

.LikeSVG {
  width: 32px;
  height: 32px;
  font-size: 20px;
  background-color: var(--color-info);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  animation-name: SizeUp;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes SizeUp {
  0%   {font-size: 20px;}
  50%  {font-size: 19px;}
  100% {font-size: 20px;}
}

div.superlikesArea,
div.likeArea {
  display: grid;
  justify-items: center;
}


/* Yorumlar */
div.CommentsArea {
  margin: 0 0 10px 0;
  padding: 10px;
}

span.CommentsCount  {
  position: absolute;
  margin-top: -30px;
  margin-left: 150px;
  border-radius: 50%;
  padding: 1px 6px;
  font-size: 14px;
  color: darkorange;
  border: 1px solid;
  text-align: center;
}

.Comments {
  margin-bottom: 20px;
}

div.CommentsHeader {
  /* margin-bottom: 5px; */
  font-size: 32px;
  font-weight: 700;
  text-decoration: underline;
}

span.FirstComment {
  color: var(--color-dark-varient);
}

div.addComment {
  display: flex;
  height: 100%;
  max-height: 120px;
}

div.CommentsArea button.CommAddButton {
  background-color: var(--color-info) !important;
  text-decoration: none !important;
  text-transform: capitalize !important;
  display: inline-flex !important;
  align-items: center !important;
  gap: 5px !important;
  color: var(--color-white) !important;
  padding: 10px 20px !important;
  border-radius: 0 5px 5px 0 !important;
  font-weight: 600 !important;
  margin: 0 0 10px 0 !important;
  width: auto !important;
  height: 80px !important;
}

div.addComment textarea {
  width: 100%;
  height: 80px;
  padding: 5px 7px;
  border: 2px solid var(--color-white2);
  border-radius: 5px 0 0 5px;
  background-color: var(--color-white2);
  resize: none;
}

span.CommentLogin {
  font-size: 32px;
  color: var(--color-text-author);
  background: var(--color-white2);
  border: 2px solid var(--color-text-primary);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  width: 540px;
  font-weight: bold;
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

div.comment {
  margin: 0 0 10px 0;
  padding: 10px;
  border: 1px solid #6a67ce!important;
  border-radius: 5px;
  background-color: var(--color-white2);
  color: var(--color-black);
}

span.commentAuthor {
  margin-left: 3px;
  font-size: 16px;
  color: var(--color-text-author);
  font-weight: bold;
}

span.commentPremium {
  font-size: 16px;
  font-weight: bold;
  background: linear-gradient(120deg,#0e6994,#00510d,#cf8a23,#f32170,#0e6994,#00510d,#cf8a23);
  background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 6s linear infinite;
}
span.commentAuthor a{
  text-decoration: none !important;
}

span.commentAuthor a::before {
  content: '@';
}

span.commentAuthorHeader {
  font-size: 12px;
  line-height: 1.8rem;
  color: var(--color-text-secondary);
}

span.commentTime {
  font-size: 12px;
  line-height: 1.8rem;
  color: var(--color-text-secondary);
  margin-left: 10px;
}

button.CommDelButton {
  position: absolute;
  margin-top: -100px;
  width: 120px;
  height: 20px;
  font-size: 11px;
  margin-left: 400px;
  border-radius: 20px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-danger);
  color: rgb(201, 201, 201);
  font-weight: 600;
}
button.NotificationDelButton {
  position: absolute;
  margin-top: -50px;
  width: 120px;
  height: 20px;
  font-size: 11px;
  margin-left: 400px;
  border-radius: 20px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-danger);
  color: rgb(201, 201, 201);
  font-weight: 600;
}

span.commentTime::before {
  content: '| ';
  margin-right: 5px;
}

div.commentInfo {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-text-secondary);
  margin-bottom: 10px;
}

div.commentInfo img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  margin-right: 10px;
}

div.commentInfo div {
  display: flex;
  align-items: center;
}

div.comment p {
  margin: 0;
  line-height: 1.4rem;
}

div.post p.info a.author {
  color: var(--color-text-author);
}

.postInfoWnL {
  text-align: center;
  padding: 2px 6px;
  margin-right: 5px;
  border: 1px solid gray;
  border-radius: 5px;
}

div.post p.summary {
  margin: 0;
  line-height: 1.4rem;
}

div.post p.summary::after {
  content: ' ...';
}

div.post p.summary::first-letter {
  color: gold;
  text-transform: uppercase;
  font-size: x-large;
  font-weight: 600;
}

div.allPosts p.summary::first-letter {
  color: goldenrod;
}

div.bioArea {
  display: grid;
  justify-items: center;
}

div.bioArea form {
  width: 80%;
}

div.bioArea .bioEditArea {
  text-align: center;
}

div.bioArea .bioEditArea textarea {
  width: 100%;
  height: 360px;
  padding: 5px 7px;
  border: 2px solid var(--color-white2);
  border-radius: 5px;
  background-color: var(--color-white2);
  resize: none;
}

div.bioArea .bioEditButton {
  width: 30%;
  background-color: var(--color-info);
  color: white;
  font-weight: 600;
  border-radius: 25px;
  margin-bottom: 10px;
}

div.bioArea .bioApplyButton {
  background-color: var(--color-success);
  font-size: large;
}


/* Login & Register Css */
input,
select {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  padding: 5px 7px;
  border: 2px solid var(--color-white2);
  border-radius: 5px;
  background-color: var(--color-white2);
}

button {
  width: 100%;
  display: block;
  background-color: var(--color-dark-varient);
  border: 0;
  color: var(--color-white);
  border-radius: 5px;
  padding: 7px 0;
  cursor: pointer;
}

form.login h1, form.register h1 {
  text-align: center;
  margin-bottom: 20px;
}

.requestArea {
  display: flex;
  justify-content: center;
}

.requestArea button {
  background-color: #1d5700;
  width: 330px;
  border-radius: 0 0 25px 25px;
}

.Verify{
  display: grid !important;
}

.Verify .login {
  border-radius: 25px 25px 0 0;
}

.verifyArea {
  display: flex;
  justify-content: center;
}

.MailVerified {
  background: green;
  padding: 10px;
  color: white;
  border-radius: 25px;
}

.MailNotVerified {
  background: red;
  padding: 10px;
  color: white;
  border-radius: 25px;
  display: grid;
  justify-items: center;
}

.verifyLink {
  color: black;
}

/* Post Page */
.mention-link {
  color: #007bff !important;
  text-decoration: none !important;
}

.mention-link:hover {
  text-decoration: underline !important;
}

div.post-page  div.image{
  display: flex;
  max-height: 300px;
  overflow: hidden;
}

div.post-page .content p:has(img) {
  text-align: center;
}

.popup {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 200px;
  margin-top: 5px;
}

.popup-item {
  padding: 10px;
  cursor: pointer;
  color: var(--color-black);
}

.popup-item:hover {
  background-color: #f5f5f5;
}

.content p:first-child:first-letter {
  color: gold;
  text-transform: uppercase;
  font-size: x-large;
  font-weight: 600;
}

div.post-page div.image img {
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.post-page pre {
  background-color: #00071c !important;
  color: #fff !important;
  padding-top: 20px !important;
}

div.post-page a{
  text-decoration: underline;
  color: inherit;
}

div.post-page h1 {
  margin: 10px 0 5px;
  text-align: center;
}

div.post-page time {
  display: block;
  text-align: center;
  font-size: .75rem;
  color: var(--color-light);
}

div.post-page div.author {
  text-align: center;
  margin-bottom: 20px;
  font-size: .8rem;
  font-weight: bold;
}

div.post-page div.content p {
  line-height: 1.6rem;
}

div.actions {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 0 0 10px 0;
}

div.actions a.edit,
div.actions a.approve,
div.actions a.delete {
  background-color: var(--color-dark-varient);
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: var(--color-white);
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
}

.ck-editor__editable.ck-content .todo-list .todo-list__label>span[contenteditable=false]>input:before{
  top: 0 !important;
  left: 0 !important;
}

div.actions svg {
  width: 20px;
  height: 20px;
}

div.actions a.edit{
  background-color: var(--color-info);
}
div.actions a.delete {
  background-color: var(--color-danger);
}
div.actions a.approve {
  background-color: var(--color-success);
}

.EditHeader {
  display: flex;
  justify-content: center;
  font-size: xx-large;
  font-weight: bold;
  margin-bottom: 15px;
  border-bottom: 1px solid #cacaca;
}

div.image img{
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.answerArea {
  display: grid;
  justify-items: start;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
}

.authorArea .author {
  color: var(--color-dark) !important;
  text-transform: capitalize !important;
  text-align: left !important;
  margin-bottom: 0 !important;
  font-size: 16px !important;
  font-weight: normal !important;
}

.authorArea .author a {
  color: var(--color-info);
  text-decoration: none;
  font-weight: 600;
}

.authorArea time {
  font-size: 12px;
  line-height: 1.8rem;
  color: var(--color-info-dark);
}

.answerArea span {
  font-size: 1.2rem;
  font-weight: bold;
}

.answer {
  cursor: pointer;
  display: flex;
  justify-items: start;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  background: #fff;
  color: #333;
  width: 100%;
  height: 100%;
}

.answer::before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  border: 2px solid #333;
  cursor: pointer;
  margin-left: 10px;
}

.active-answer::before {
  content: '';
  display: block;
  background: #333;
  width: 8px;
  height: 8px;
  border: 2px solid #fff;
  outline: 2px solid #333;
}

.active-answer {
  background: #8ccc6f;
}

/* Notification Page */
.NotificationListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 10px;
  border-radius: 15px;
  background: var(--color-dark-light);
  margin-bottom: 20px;
  color: var(--color-white2);
}

.NotificationListItem .commentTime {
  font-size: 12px;
  line-height: 1.8rem;
  color: var(--color-primary);
  margin-left: 10px;
}

.NotificationArea {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.NotificationArea a {
  text-decoration: none;
  color: var(--color-info);
}

.navigator-items {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}


.system-item {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 0;
  transition: transform 0.3s ease;
  width: 456px;
  height: 400px;
  margin: 0;
  padding: 0;
  border: none;
}

.system-item img {
  width: 100%;
  height: 100%;
  display: block;
  transition: transform 0.3s ease;
}

.system-item-div {
  position: relative;
  height: 100%;
  width: 100%;
}

.system-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  padding: 10px;
  font-size: 18px;
  font-weight: 400;
  /* box-shadow: 0px -90px 50px -35px #000000AB inset; */
  box-shadow: 0px -70px 50px -35px #000000AB inset;
  cursor: pointer;
}

.system-overlay:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 50%);
}

.system-overlay span {
  position: absolute;
  bottom: 12px;
  left: 16px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
/* Ticket Control Page */
.TicketArea {
  grid-template-columns: 1fr;
  gap: 20px;
  /* background: darkslategray; */
  border-radius: 15px;
}

.TicketInfo {
  display: grid;
  justify-items: center;
  align-items: start;
  background: darkslategray;
  border-radius: 0 0 15px 15px;
  padding: 10px;
  width: 100%;
}

.TicketInfo .author {
  background-color: #212121;
  color: white;
  margin: 1px;
  border-radius: 15px;
  padding: 10px;
}

.TicketInfo .author a {
  color: red;
  text-decoration: none;
  text-transform: uppercase;
}

.TicketInfo h1 {
  margin: 0;
}


/* Settings Area */
.settingsArea {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  border-radius: 15px;
  justify-items: center;
  text-align: center;
}

.settingsArea .dark-mode {
  margin-bottom: 25px;
}

.themaSwitch {
  border-bottom: 1px solid #cacaca;
  width: 100%;
  display: grid;
  justify-items: center;
}

.profil-settings-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.profil-settings-content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
}

.profil-settings {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.logoutButton {
  color: var(--color-white);
  background-color: var(--color-danger);
  border-radius: 15px;
  padding: 10px;
}

.loginButton {
  color: var(--color-white);
  background-color: var(--color-info);
  border-radius: 15px;
  padding: 10px;
}

.asideButton {
  color: var(--color-white);
  background-color: var(--color-warning);
  border-radius: 15px;
  padding: 10px;
}

.SendTicket {
  color: var(--color-white);
  background-color: var(--color-info);
  border-radius: 15px;
  padding: 10px;
}

.ViewTicket {
  color: var(--color-white);
  background-color: var(--color-success);
  border-radius: 15px;
  padding: 10px;
}

.SendTicket a,
.ViewTicket a,
.logoutButton a,
.loginButton a,
.asideButton a {
  text-decoration: none;
  color: inherit;
}

.teory {
  background: linear-gradient(to right, #f32170, 
          #ff6b08, #cf23cf, #eedd44); 
  -webkit-text-fill-color: transparent; 
  -webkit-background-clip: text; 
}

@media screen and (max-width: 768px) {
  div.post {
    grid-template-columns: 1fr;
  }
  .content {
    padding: 0 20px;
  }
  
  span.CommentLogin {
    font-size: 18px;
    color: var(--color-text-author);
    background: var(--color-white2);
    border: 2px solid var(--color-text-primary);
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    width: auto;
    max-width: 540px;
    font-weight: bold;
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }

  div.LikesArea::before {
    margin: 20px 0 0 20px;
  }
}

@media screen and (min-width: 768px) {
  div.post {
    grid-template-columns: 0.9fr 1.1fr;
  }
}

@media screen and (min-width: 1024px) {
  div.post {
    grid-template-columns: 0.8fr 1.2fr;
  }
}

@media screen and (min-width: 1200px) {
  div.post {
    grid-template-columns: 0.7fr 1.3fr;
  }
}

@media screen and (min-width: 1400px) {
  div.post {
    grid-template-columns: 0.6fr 1.4fr;
  }
}

@media screen and (max-width: 768px) {
  button.CommDelButton {
    margin-left: 175px;
  }
  .areas {
    flex-direction: column !important;
  }
}

@media screen and (max-width: 430px) {
  .content {
    padding: 0 3px;
    max-width: 430px;
    min-height: 585px;
  }

  .post-page {
    width: 98%;
  }

  .related-posts .posts .post .image,
  .related-posts .posts .post .text {
    width: 98%;
  }



  .NotificationListItem {
    flex-wrap: wrap;
    gap: 0;
  }


  button.NotificationDelButton {
    margin-left: 200px;
    margin-top: 90px;
  }

  pre.ql-syntax {
    overflow: scroll !important;
  }
}
@media screen and (max-width: 360px) {
  .content {
    padding: 0 3px;
    max-width: 360px;
    font-size: small;
  }

  pre.ql-syntax {
    overflow: scroll !important;
  }
}
@media screen and (max-width: 350px) {
  .content {
    padding: 0 1px;
    min-height: 290px;
  }
  div.allPosts {
    width: 260px;
    font-size: x-small;
  }

  div.allPosts h1, div.allPosts p.summary {
    display: -webkit-box;
  }

  button.NotificationDelButton {
    margin-left: 140px;
    margin-top: 150px;
  }

  button.CommDelButton {
    margin-left: 138px;
  }

  pre.ql-syntax {
    font-size: xx-small;
    overflow: scroll !important;
  }
}