header {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  align-items: center;
  background: var(--color-nav);
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999999;
}
.header {
  margin-bottom: 20px;
}

header a {
  text-decoration: none;
  color: inherit;
}

header a.logo {
  width: auto;
  max-width: 128px;
  font-weight: bold;
  font-size: 1.5rem;
  display: flex;
}

header nav {
  width: 50%;
  display: flex;
  gap: 15px;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: space-around;
}

/* Dropdown Styles */
.dropdown, .UDropdown {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: white;
  padding: 2px;
  width: max-content;
}

.dropdowns {
  display: flex;
  gap: 50px;
}

.dropdown-content, .dropdown-content2 {
  top: 35px;
  left: -1px;
  display: none;
  position: absolute;
  background-color: white;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.5);
  z-index: 1;
  text-align: center;
  font-weight: 400;
  width: 100%;
}

.dropdown-content svg, .dropdown-content2 svg {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.dropdown-content a, .dropdown-content2 a {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.dropdown:hover .dropdown-content,
.dropdown:hover .dropdown-content2,
.UDropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a,
.dropdown-content2 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.dropdown-content a:hover,
.dropdown-content2 a:hover {
  background-color: rgb(0, 0, 0, 0.1);
}


.dropdown-content a:nth-last-child(1) {
  border-bottom: none;
}

div .dropbtn {
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin: 5px;
}

div .dropbtn svg {
  width: 24px;
  height: 24px;
  background-color: var(--color-nav);
  border-radius: 50%;
  color: white;
}

.header-username {
  text-transform: capitalize;
  cursor: pointer;
  font-weight: lighter;
  font-size: 1.2rem;
  margin-left: 2px;
  user-select: none;
}

.AdminButton {
  background: linear-gradient(to right top, rgb(218, 32, 88), #345678);
  color: white !important;
}

.AdminButton:hover {
  background: linear-gradient(to left bottom, rgb(181, 32, 218), #345678);
  color: white !important;
  
}


.dropbtn::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 5px solid white;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin-left: 5px;
  vertical-align: middle;
}

.create-post {
  background-color: var(--color-nav);
  color: white;
  padding: 2px;
  font-weight: lighter;
  font-size: 1.2rem;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.create-post svg {
  width: 40px;
  height: 40px;
  color: white;
}

.create-post a {
  text-decoration: none;
  color: inherit;
  display: inline-flex;
  align-items: center;
}

.logo img {
  width: 128px;
  height: auto;
  user-select: none;
  cursor: pointer;
}


header .nav {
  width: 50%;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-evenly;
}

.login-text {
  font-size: 12px;
  color: goldenrod;
  padding-bottom: 15px;
}

.login-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.ProfilePhoto {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  margin-right: 5px;
}

.dropbtn span {
  text-transform: capitalize;
  user-select: none;
}

.notificationsButton {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  /* background-color: var(--color-nav); */
  border-radius: 50%;
  color: white;
  padding: 2px;
  width: max-content;
}

.newNatificationsActive {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  background-color: var(--color-danger);
  border-radius: 50%;
  color: white;
  padding: 2px;
  width: max-content;
}

.notificationsButton a {
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin: 5px;
}

.notificationsButton svg {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: var(--color-dark);
}

.premiumHeader {
  position: relative;
  top: -5px;
  font-size: 10px;
  text-align: center;
  font-weight: 700 !important;
  background: linear-gradient(120deg, #21b1f3, #00510d, #cfb523, #f32170, #21b1f3, #00510d, #cfb523);
  /* background: linear-gradient(to right top, #00ffff, #ff00ff); */
  background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 3s linear infinite;
}

.premiumHeaderMobil {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-align: center;
  font-weight: 700 !important;
  background: linear-gradient(120deg, #21b1f3, #00510d, #cfb523, #f32170, #21b1f3, #00510d, #cfb523);
  /* background: linear-gradient(to right top, #00ffff, #ff00ff); */
  background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 3s linear infinite;
}

.exchange-rate {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  font-weight: lighter;
  color: var(--color-white2) !important;
  background-color: var(--color-dark-varient) !important;
  color: black;
  padding: 1px 200px;
  z-index: 9999999;
}

.exchange-rate svg,
.exchange-rate img{
  width: 20px;
  height: 20px;
  margin: 0 5px;
}

.gold {
  height: 18px !important;
  width: 18px !important;
}

.exchange-rate span {
  display: flex;
  align-items: center;
}

.upChange {
  color: green;
  filter: drop-shadow(2px 1px 0.75px rgb(0 0 0 / 0.8));
  font-weight: 900;
}

.downChange {
  color: red;
  filter: drop-shadow(2px 0px 0.75px rgb(0 0 0 / 0.8));
  font-weight: 900;
}

.exchange-rate span::after {
  content: " | ";
  height: 100%;
  display: inline-block;
  font-size: initial;
}

.exchange-rate span:nth-last-child(1)::after {
  content: "";
}

.profileButton img {
  border: 1px solid rgba(56, 56, 56, 0.5);
}

.HeadNav {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background: var(--color-white);
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}

@media (max-width: 1200px) {
  header nav {
    display: flex;
    margin-top: 10px;
    width: 66%;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  header {
    padding: 5px 20px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .header {
    flex-direction: column;
    align-items: center;
  }

  header a.logo {
    font-size: 1rem;
  }

  header a.logo img {
    display: flex;
  }

  header .create-post {
    margin-left: 0;
  }

  header .create-post svg {
    width: 30px;
    height: 30px;
  }

  header .header-username {
    font-size: 1rem;
  }

  header .dropdown, header .UDropdown {
    margin-left: 10px;
    display: grid;
  }
  .dropdown:hover .dropdown-content,
  .dropdown:hover .dropdown-content2,
  .UDropdown:hover .dropdown-content {
    display: contents;
  }

  header .dropdown-content,
  header .dropdown-content2 {
    left: -10px;
  }

  header .dropdown-content a,
  header .dropdown-content2 a {
    padding: 10px 10px;
    background-color: #fff;
  }

  header .dropdown-content a:nth-last-child(1) {
    border-bottom: none;
  }

  header .dropdown-content2 a:nth-last-child(1) {
    border-bottom: none;
  }

  header .dropdown .dropbtn svg {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 1300px) {
  .exchange-rate {
    gap: 1px;
    padding: 2px 0;
  }

  .exchange-rate span::after {
    content: " | ";
    height: 100%;
    display: inline-block;
    font-size: initial;
  }

  .exchange-rate span:nth-last-child(1)::after {
    content: "";
  }

  .exchange-rate svg {
    margin: 0;
  }

  .downChange, .upChange {
    font-size: 8px;
    font-weight: 200;
    margin: 0 !important;
  }
}

@media (max-width: 768px) {
  .exchange-rate {
    font-size: 12px;
    gap: 1px;
    padding: 2px 0;
  }

  .exchange-rate span::after {
    content: " | ";
    height: 100%;
    display: inline-block;
    font-size: initial;
  }

  .exchange-rate span:nth-last-child(1)::after {
    content: "";
  }

  .exchange-rate svg {
    margin: 0;
  }

  .downChange, .upChange {
    font-size: 8px;
    font-weight: 400;
    margin: 0 !important;
  }
}

@media (min-width: 801px) {
  .Mobile-Responsive {
    display: none;
  }
  .HeadNav {
    display: none;
  }
}

@media (max-width: 800px) {
  header {
    display: none;
  }

  .Mobile-Responsive {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0;
    background: var(--color-white2);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 9999999;
  }

  .setttingsButton,
  .homePageButton,
  .createBlogButton,
  .profileButton,
  .searchPageButton {
    width: 20%;
    display: flex;
    justify-content: center;
    vertical-align: middle;
  }

  .setttingsButton,
  .homePageButton,
  .createBlogButton,
  .searchPageButton {
    border-right: 1px solid rgba(54, 54, 54, 0.3);
  }

  .setttingsButton svg,
  .homePageButton svg,
  .createBlogButton svg,
  .profileButton svg,
  .searchPageButton svg {
    width: 36px;
    height: 36px;
    align-items: center;
  }
  
  .setttingsButton a,
  .homePageButton a,
  .createBlogButton a,
  .profileButton a,
  .searchPageButton a{
    text-decoration: none;
    color: #000;
  }
  
  .exchange-rate {
    font-size: 12px;
    font-weight: 600;
  }

  .gold {
    height: 13px !important;
    width: 13px !important;
  }

  .upChange {
    display: none;
  }
  .downChange {
    display: none;
  }

  .aside-title,
  .aside-content {
    z-index: -1;
  }

  main {
    display: contents;
  }
}

@media (max-width: 400px) {
  .exchange-rate {
    font-size: 10px;
    font-weight: 600;
  }
}